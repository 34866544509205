<template>
  <div class="row">
    <div class="col-md-12" style="background: white">
      <table class="table table-striped">
        <thead>
          <tr>
            <th colspan="3">
              <input
                v-model="searchInput"
                type="text"
                placeholder="Search Company or Abbreviation Code"
                class="form-control"
              />
            </th>
            <!-- <th colspan="2">
              <input
                v-model="abbrCode"
                type="text"
                placeholder="Search Abbreviation Code"
                class="form-control"
              />
            </th> -->
            <th></th>
            <th></th>
            <th colspan="5">
              <router-link
                :to="{ name: 'client.company.create' }"
                v-if="currentUser.u.roles == 'admin'"
              >
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add Abbreviation
                </div>
              </router-link>
            </th>
          </tr>
          <tr>
            <th>SN</th>
            <th scope="col">Company Name</th>
            <th>Code</th>
            <th>Quota</th>
            <th>Used</th>
            <th scope="col">Expiration Date</th>
            <th>Contact Name</th>
            <th class="text-center">Show Payment</th>
            <th class="text-center">Status</th>
            <th
              style="width: 14%;"
              scope="col"
              v-if="currentUser.u.roles == 'admin'"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody v-if="!loadingData && filteredCompanies.length > 0">
          <tr v-for="(item, index) in filteredCompanies" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.company_name }}</td>
            <td>{{ item.abbreviation_code }}</td>
            <td>{{ item.quota }}</td>
            <td>{{ item.code_used }}</td>
            <td>
              {{ item.valid_from | convertDateFormat }} ~
              {{ item.valid_to | convertDateFormat }}
            </td>

            <td>{{ item.contact_name }}</td>
            <td class="text-center">
              <i
                class="fa fa-times text-danger"
                v-if="!item.show_payment_option"
              ></i>
              <i
                class="fa fa-check text-success"
                v-if="item.show_payment_option"
              ></i>
            </td>
            <td class="text-center">
              <i class="fa fa-times text-danger" v-if="!item.is_active"></i>
              <i class="fa fa-check text-success" v-if="item.is_active"></i>
            </td>
            <td v-if="currentUser.u.roles == 'admin'">
              <button
                @click="editData(item.id)"
                class="btn btn-info btn-sm mr-3"
              >
                Edit
              </button>
              <button
                @click="deleteItem(item.id, index)"
                class="btn btn-danger btn-sm"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>

        <tbody v-if="!loadingData && filteredCompanies.length < 1">
          <tr>
            <td colspan="10" class="text-center">
              No record(s) found
            </td>
          </tr>
        </tbody>

        <tbody v-if="loadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
        <!----><!---->
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_COMPANIES,
  DELETE_COMPANY
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
import vDataLoader from "@/components/frontend/spinner.vue";
export default {
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Abbreviations",
          route: ""
        }
      ],
      loadingData: false,
      searchInput: "",
      abbrCode:"",
    };
  },
  components: {
    vDataLoader
  },
  computed: {
    ...mapGetters(["getCompanies", "currentUser"]),
    filteredCompanies() {
      let tempCompany = this.getCompanies;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempCompany = tempCompany.filter(item => {
          return item.company_name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase())||item.abbreviation_code
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      // if (this.abbrCode != "" && this.abbrCode) {
      //   tempCompany = tempCompany.filter(item => {
      //     return item.abbreviation_code
      //       .toUpperCase()
      //       .includes(this.abbrCode.toUpperCase());
      //   });
      // }
      return tempCompany;
    }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getCompaniesData();
  },
  filters: {
    convertDateFormat(value) {
      return moment(value).format("MM/DD/YYYY");
    }
  },
  methods: {
    deleteItem(id, index) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover !",

        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store.dispatch(DELETE_COMPANY, id).then(data => {
            this.$toastr.s(data.msg);
            this.getCompanies.splice(index, 1);
          });
        }
      });
    },
    editData(id) {
      this.$router.push({ name: "client.company.edit", params: { id: id } });
    },
    getCompaniesData() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_COMPANIES)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    }
  }
};
</script>

<style></style>
